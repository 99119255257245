<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter ml-auto">
                <report-menu @filter_show="handleFilterShow"></report-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                    <v-form @submit.prevent="getCountChart('search')">
                        <v-card flat class="background_color_transparent">
                            <v-card-text class="py-0">
                                <v-row class="pt-3">
                                    <v-col cols="12" sm="12" class="title_menu mt-1">
                                        {{ filter_show ? $t('menu_calls') : $t('menu_calls') }}
                                    </v-col>
                                </v-row>
                                <v-row v-if="filter_show">
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuFrom" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateFrom" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateFrom" :error-messages="errors"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateFrom = null"
                                                                  :label="$t('date_from')"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateFrom"
                                                           :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuFrom = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuTo" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateTo" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateTo" :error-messages="errors"
                                                                  :label="$t('date_to')"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateTo = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateTo"
                                                           :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :max="$moment().format('YYYY-MM-DD')" :locale="lang"
                                                           @change="dateMenuTo = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions v-if="filter_show" class="px-4 py-4">
                                <v-row>
                                    <v-col cols="12" sm="3" md="2">
                                        <v-select :value="perPage" :items="perPageItems"
                                                  :label="$t('items_per_page')"
                                                  @input="perPage = options.itemsPerPage = Number($event)"
                                                  hide-details
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="9" md="10"
                                           class="d-flex justify-center justify-sm-end align-self-center">
                                        <v-btn @click="searchFilter" :disabled="loading" :block="$vuetify.breakpoint.xsOnly"
                                               color="primary" class="infinity_button">{{ $t('search') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </ValidationObserver>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-row>
                            <v-col class="pt-0" cols="12">
                                <div class="d-flex">
                                    <div >
                                        <v-btn text @click="type_call = 'day'" :color="type_call === 'day' ? 'primary' : ''">
                                            {{$t('day')}}
                                        </v-btn>
                                        <v-btn text @click="type_call = 'month'" :color="type_call === 'month' ? 'primary' : ''">
                                            {{$t('month')}}
                                        </v-btn>
                                    </div>
                                    <div class="ml-auto">
                                        <v-select
                                            v-model="m_header"
                                            :items="headers"
                                            chips
                                            label="Chips"
                                            multiple
                                            solo
                                            return-object
                                            item-text="text" item-value="value"
                                            hide-details
                                            flat
                                            dense
                                            outlined
                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"

                                            @change="updateHeaders"
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"

                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>{{ item.text }}</span>
                                                </v-chip>
                                                <span
                                                    v-if="index === 1"
                                                    class="grey--text text-caption"
                                                >
                                                  (+{{ m_header.length - 1 }} {{$t('others')}})
                                                </span>
                                            </template>
                                        </v-select>
                                    </div>

                                </div>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <v-card class="d-flex flex-wrap" flat tile>
                                    <template v-for="(chart, i) in charts">
                                    <v-card  v-if="type_call === chart.type_call"
                                            min-width="100%"
                                            max-width="100%"
                                            class="pa-0 ma-0  mx-auto text-center" elevation="3" outlined tile>
                                        <line-chart :chartdata="chart" :options="optionsChartBar"></line-chart>
                                    </v-card>
                                    </template>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-data-table :headers="headers2" :items="listAdminItems" :loading="loading" :locale="lang"
                                      :loading-text="$t('loading_please_wait')"
                                      disable-pagination hide-default-footer
                                      :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                      class="elevation-0"
                                      :item-class="rowClass2">
                            <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                <thead>
                                <tr class="rowHeadClass Table2">
                                    <th v-for="h in headers" :key="h.value" class="table_header">
                                        <span>{{h.text}}</span>
                                    </th>
                                </tr>
                                </thead>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                    <span class="font_weight_600" >
                                        {{  item.name }}
                                    </span>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-card flat class="background_color_transparent1">
                    <v-card-text class="pt-7">
                        <v-data-table :headers="filteredHeaders" :items="listtems" :options.sync="options" :page.sync="page"
                                      :items-per-page="perPage" :server-items-length="totalLists"
                                      :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                                      @page-count="pageCount = options.pageCount = Number($event)"
                                      :loading-text="$t('loading_please_wait')"
                                      hide-default-footer

                                      :item-class="rowClass">




                            <template v-slot:item.start_time="{ item }">
                                <div :class="item.is_lost ? 'text_red' : ''">{{ $moment(item.start_time).format('DD.MM.YYYY HH:mm')  }}</div>
                            </template>
                            <template v-slot:item.finish_time="{ item }">
                                <div :class="item.is_lost ? 'text_red' : ''">{{ $moment(item.finish_time).format('DD.MM.YYYY HH:mm')  }}</div>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                <div :class="item.is_lost ? 'text_red' : ''">{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm')  }}</div>
                            </template>
                            <template v-slot:item.clean_talk_duration="{ item }">
                                <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.clean_talk_duration, 'seconds').asMilliseconds()).format('mm:ss')  }}</div>
                            </template>

                            <template v-slot:item.wait_duration="{ item }">
                                <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.wait_duration, 'seconds').asMilliseconds()).format('mm:ss')  }}</div>
                            </template>
                            <template v-slot:item.lost_call_processing_duration="{ item }">
                                <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.lost_call_processing_duration, 'seconds').asMilliseconds()).format('mm:ss')  }}</div>
                            </template>
                            <template v-slot:item.talk_duration="{ item }">
                                <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.talk_duration, 'seconds').asMilliseconds()).format('mm:ss')  }}</div>
                            </template>
                            <template v-slot:item.total_duration="{ item }">
                                <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.total_duration, 'seconds').asMilliseconds()).format('mm:ss')  }}</div>
                            </template>


                            <template v-slot:item.contact_phone_number="{ item }">
                                <div class="phone" :class="item.is_lost ? 'text_red' : ''">
                                    {{ formatPhoneNumber(item.contact_phone_number) }}
                                </div>
                            </template>
                            <template v-slot:item.virtual_phone_number="{ item }">
                                <div class="phone" :class="item.is_lost ? 'text_red' : ''">
                                    {{ formatPhoneNumber(item.virtual_phone_number) }}
                                </div>
                            </template>
                            <template v-slot:item.url="{ item }">
                                <div>
                                    <a v-if="item.url" :href="item.url" target="_blank">
                                    <v-icon >
                                        mdi-play-circle-outline
                                    </v-icon>
                                    </a>
                                </div>
                            </template>
                            <template v-slot:item.direction="{ item }">
                                <div>
                                    <v-icon v-if="item.direction === 'in'" color="primary">
                                        mdi-phone-incoming
                                    </v-icon>
                                    <v-icon v-else color="green">
                                        mdi-phone-outgoing
                                    </v-icon>
                                </div>
                            </template>


                            <template v-slot:item.admin.name="{ item }" >
                                <div :class="item.is_lost ? 'text_red' : ''">
                                        {{ item.admin.name }}
                                </div>
                            </template>

                            <template v-slot:item.uved_name="{ item }" >
                                <div :class="item.is_lost ? 'text_red' : ''">
                                    <router-link :to="{'name': 'uved.show', 'params': {'id': item.uved_uuid}}">
                                      {{ item.uved_name }}
                                    </router-link>
                                </div>
                            </template>


                            <template v-slot:item.is_lost="{ item }" >
                                <div :class="item.is_lost ? 'text_red' : ''">{{ item.is_lost ? $t('yes') : $t('no')}}</div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions class="pt-0 justify-end">
                        <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table"
                                      :length="pageCount" :disabled="loading"></v-pagination>
                    </v-card-actions>
                </v-card>


            </div>
        </div>

    </v-container>
</template>
<script>



import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import BarChart from "../../plugins/charts/BarChart";
import LineChart from "../../plugins/charts/LineChart";
import ReportMenu from "../../components/ReportMenu";


export default {
    name: "Dashboard",
    components: {
        ReportMenu,
        //  BarChart,
        LineChart,
        ValidationProvider,
        ValidationObserver

    },
    data() {
        return {
            loading:false,
            charts: [],
            optionsChartLine: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            //stepSize: 1,
                            beginAtZero: true,
                            callback: function (value) {
                                if (value % 1 === 0) {
                                    return value;
                                }
                            }
                        }
                    }]
                },
            },
            optionsChartBar: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            //stepSize: 1,
                            beginAtZero: true,
                            callback: function (value) {
                                if (value % 1 === 0) {
                                    return value;
                                }
                            }
                        }
                    }]
                },
            },

            filter_show: true,
            email_search: null,
            type_call: 'month',
            listtems: [],
            sortBy: "start_time",
            sortDir: false,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalLists: 0,
            options: {},
            headers: [
                {
                    text: this.$t('type'),
                    align: "left",
                    sortable: true,
                    value: "direction",
                    hide: false
                },
                {
                    text: this.$t('manager'),
                    align: "left",
                    sortable: true,
                    value: "admin.name",
                    hide: false
                },
                {
                    text: this.$t('start_call'),
                    align: "left",
                    sortable: true,
                    value: "start_time",
                    hide: false
                },
                {
                    text: this.$t('finish_call'),
                    align: "left",
                    sortable: false,
                    value: "finish_time",
                    hide: true
                },
                {
                    text: this.$t('clean_talk_duration'),
                    align: "left",
                    sortable: true,
                    value: "clean_talk_duration",
                    hide: false
                },
                {
                    text: this.$t('contact_phone_number'),
                    align: "left",
                    sortable: true,
                    value: "contact_phone_number",
                    hide: false
                },
                {
                    text: this.$t('record'),
                    align: "left",
                    sortable: true,
                    value: "url",
                    hide: false
                },

                {
                    text: this.$t('is_lost'),
                    align: "left",
                    sortable: true,
                    value: "is_lost",
                    hide: true
                },

                {
                    text: this.$t('uved_name'),
                    align: "left",
                    sortable: true,
                    value: "uved_name",
                    hide: true
                },




                {
                    text: this.$t('virtual_phone_number'),
                    align: "left",
                    sortable: true,
                    value: "virtual_phone_number",
                    hide: true
                },
                {
                    text: this.$t('wait_duration'),
                    align: "left",
                    sortable: true,
                    value: "wait_duration",
                    hide: true
                },
                {
                    text: this.$t('lost_call_processing_duration'),
                    align: "left",
                    sortable: true,
                    value: "lost_call_processing_duration",
                    hide: true
                },

                {
                    text: this.$t('talk_duration'),
                    align: "left",
                    sortable: true,
                    value: "talk_duration",
                    hide: true
                },
                {
                    text: this.$t('total_duration'),
                    align: "left",
                    sortable: true,
                    value: "total_duration",
                    hide: true
                },
                {
                    text: this.$t('date_created'),
                    align: "left",
                    sortable: true,
                    value: "created_at",
                    hide: true
                },

            ],
            m_header: [],
            dateFrom: null,
            dateMenuFrom: false,
            dateTo: null,
            dateMenuTo: false,
            listAdminItems: [],
            headers2:[
                {
                    text: this.$t('manager'),
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t('quantity_short'),
                    align: "left",
                    sortable: true,
                    value: "count",
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    watch: {
        options: {
            handler() {
                this.getCountList()
            },
            deep: false
        }
    },
    mounted() {
        this.dateFrom = this.$moment().startOf('month').format('YYYY-MM-DD');
        this.dateTo = this.$moment().format('YYYY-MM-DD');
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getCountChart()
        this.getCountListAdmin()
        for (let i = 0; i < this.headers.length; i++) {
            if(this.headers[i].hide === false){
                this.m_header.push(this.headers[i]);
            }
        }


    },
    methods: {
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClassAuto' : 'rowMobiClass';
        },
        rowClass2(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table2' : 'rowMobiClass';
        },
        updateHeaders(){
            for (let i = 0; i < this.headers.length; i++) {
                let yes = 0;
                for (let x = 0; x < this.m_header.length; x++) {
                    if(this.headers[i].value === this.m_header[x].value){
                        yes = 1;
                    }
                }
                this.headers[i].hide = yes !== 1;
            }
        },
        searchFilter(){
            this.getCountChart();
            this.getCountListAdmin();
            this.getCountList();
        },
        getCountChart() {
            let params = {};

            if (this.dateFrom) {
                params.dateFrom = this.dateFrom;
            }
            if (this.dateTo) {
                params.dateTo = this.dateTo;
            }
            this.$http
                .get('admin/reports/call_chart', {
                    params: params,
                })
                .then(res => {
                    this.charts = res.body.data
                })
                .catch(err => {
                    this.charts = [];
                    this.$toastr.error(this.$t('failed_to_get_list_charts'))
                })
        },
        async getCountList() {
            this.loading = true
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.dateFrom) {
                params.dateFrom = this.dateFrom;
            }
            if (this.dateTo) {
                params.dateTo = this.dateTo;
            }


            await this.$http
                .get("admin/reports/call", {
                    params: params,
                })
                .then(res => {
                    this.listtems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalLists = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.listtems = []
                    this.totalLists = 0
                    this.$toastr.error(this.$t('failed_to_get_list_administrators'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getCountListAdmin() {
            this.loading = true

            let params = {};


            if (this.dateFrom) {
                params.dateFrom = this.dateFrom;
            }
            if (this.dateTo) {
                params.dateTo = this.dateTo;
            }


            await this.$http
                .get("admin/reports/call_admin", {
                    params: params,
                })
                .then(res => {
                    this.listAdminItems = res.body.data
                })
                .catch(err => {
                    this.listAdminItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_administrators'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>


